
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import { ILoginRequest } from "@/types/login";
import { IUser, UserState } from "@/types/user";
@Component({
  components: {}
})
export default class extends Vue {
  @Action("login/login")
  public login!: (request: ILoginRequest) => boolean;

  @Action("auth/deauthorize")
  public deauthorize!: () => void;

  @Getter("login/redirectUrl")
  public redirectUrl!: string;

  @Getter("auth/isAdmin")
  public isAdmin!: boolean;

  @Getter("auth/isUser")
  public isUser!: boolean;

  @Getter("auth/isInvoiceOnly")
  public isInvoiceOnly!: boolean;

  @Getter("auth/me")
  public me!: IUser;

  @Getter("auth/getState")
  public getState!: () => UserState;

  @Mutation("login/clearRedirectUrl")
  public clearRedirectUrl!: () => void;

  @Mutation("auth/clearUser")
  public clearUser!: () => void;

  public form: ILoginRequest = {
    email: "",
    password: ""
  };
  public async created() {
    await Promise.all([
      this.deauthorize(),
      this.clearRedirectUrl(),
      this.clearUser()
    ]);
  }
  public async loginClicked() {
    if (!(await this.login(this.form))) {
      return;
    }
    const route_name = this.isAdmin
      ? "AdminHome"
      : this.isUser
      ? "UserHome"
      : this.isInvoiceOnly
      ? "InvoiceOnlyInvoiceList"
      : "Login";
    let route: { name?: string; path?: string } = {
      name: route_name
    };
    return this.$router.push(route);
  }
}
